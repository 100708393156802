import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import withGraphQLErrors from "../hocs/withGraphQLErrors";
import ContentLayout from "../primitives/ContentLayout";
import SectionLayout from "../primitives/SectionLayout";
import PortableText from "../primitives/PortableText";

export const query = graphql`
  query PrivacyPageQuery {
    page: sanityRoute(slug: { current: { eq: "confidentialite" } }) {
      page {
        title
        _rawContent(resolveReferences: { maxDepth: 10 })
        meta {
          metaTitle
          metaDescription
        }
      }
    }
  }
`;

const PrivacyPage = ({ data }) => {
  const { page = {} } = data || {};
  const { page: pageContent = {} } = page;

  return (
    <Layout>
      <Seo
        title={
          get(page, "page.meta.metaTitle", "") || get(page, "page.title", "")
        }
        description={get(page, "page.meta.metaDescription", "")}
      />

      <ContentLayout>
        <h1>{pageContent.title}</h1>
      </ContentLayout>

      {pageContent._rawContent &&
        pageContent._rawContent.map(
          ({ _key, sectionContent, backgroundColor }) => (
            <SectionLayout backgroundColor={backgroundColor} key={_key}>
              <ContentLayout>
                <PortableText blocks={sectionContent} />
              </ContentLayout>
            </SectionLayout>
          )
        )}
    </Layout>
  );
};

export default withGraphQLErrors()(PrivacyPage);
